import $ from 'jquery'
import Cookies from 'js-cookie'
import GMaps from 'gmaps'
import { Loader } from "@googlemaps/js-api-loader"
import { map as mapOver } from 'rambda'
/* global google:readonly */

let rnn, constants, markerStyleData, markerData, defaults, maps = [];

export function getMarkerData() {
  return markerData;
}

function initMapMarkerData() {

  function markerImgPath(name, isShadow) {
    return rnn.site.mediaURL + 'rnn/base/img/maps/markers/' +
      name + '/marker-images/' + (isShadow ? 'shadow' : 'image') + '.png';
  }

  function markerImg(url, sizeW, sizeH, originX, originY, anchorX, anchorY) {
    return new google.maps.MarkerImage(
      url,
      new google.maps.Size(sizeW, sizeH),
      new google.maps.Point(originX, originY),
      new google.maps.Point(anchorX , anchorY)
    );
  }

  // Define & populate the marker style data dictionary
  markerStyleData = {
    // Small markers
    'icon-small-blue':     markerImg(markerImgPath('blue_small'),         14, 20, 0, 0, 7, 10),
    'icon-small-black':       markerImg(markerImgPath('black_small'),           14, 20, 0, 0, 7, 10),
    'shadow-small':         markerImg(markerImgPath('black_small', true),     18, 19, 0, 0, 12, 32),
    'shape-small':          {type: 'poly', coord: [18,0, 22,7, 22,16, 12,31, 11,31, 1,16, 1,7, 14,0, 18,0]},
    // Small Alt markers
    'icon-small-alt-blue': markerImg(markerImgPath('blue_small_alt'),     22, 32, 0, 0, 11, 32),
    'icon-small-alt-black':   markerImg(markerImgPath('blue_small_alt'),       22, 32, 0, 0, 11, 32),
    'shadow-small-alt':     markerImg(markerImgPath('blue_small_alt', true), 42, 32, 0, 0, 11, 32),
    'shape-small-alt':      {type: 'poly', coord: [13,0, 21,7, 21,15, 12,31, 9,31, 0,15, 0,6, 9,0, 13,0]},
    // Large markers
    'icon-large-blue':     markerImg(markerImgPath('blue_large'),         30, 40, 0, 0, 15, 40),
    'icon-large-black':       markerImg(markerImgPath('black_large'),           30, 40, 0, 0, 15, 40),
    'shadow-large':         markerImg(markerImgPath('black_large', true),     54, 40, 0, 0, 15, 40),
    'shape-large':          {type: 'poly', coord: [23,0, 28,9, 28,21, 15,39, 14,39, 1,20, 1,9, 18,0, 23,0]}
  };
}

function initDefaults() {
  defaults = {
    map: {
      lat: constants.AU_LAT,
      lng: constants.AU_LON,
      zoom: 4,
      minZoom: 4,
      maxZoom: 17
    },
    markers: {
      small: {
        black: {
          icon: markerStyleData['icon-small-black'],
          shadow: markerStyleData['shadow-small'],
          shape: markerStyleData['shape-small']
        },
        blue: {
          icon: markerStyleData['icon-small-blue'],
          shadow: markerStyleData['shadow-small'],
          shape: markerStyleData['shape-small']
        }
      },
      small_alt: {
        black: {
          icon: markerStyleData['icon-small-alt-black'],
          shadow: markerStyleData['shadow-small-alt'],
          shape: markerStyleData['shape-small-alt']
        },
        blue: {
          icon: markerStyleData['icon-small-alt-blue'],
          shadow: markerStyleData['shadow-small-alt'],
          shape: markerStyleData['shape-small-alt']
        }
      },
      large: {
        black: {
          icon: markerStyleData['icon-large-black'],
          shadow: markerStyleData['shadow-large'],
          shape: markerStyleData['shape-large']
        },
        blue: {
          icon: markerStyleData['icon-large-blue'],
          shadow: markerStyleData['shadow-large'],
          shape: markerStyleData['shape-large']
        }
      }
    },
    // this for the desc
    overlay: {
      verticalAlign: 'top',
      horizontalAlign: 'center',
      verticalOffset: -5,
      layer: 'floatPane'
    },
    overlay_alt: {
      verticalAlign: 'bottom',
      horizontalAlign: 'right',
      layer: 'floatPane',
      verticalOffset: 0,
      horizontalOffset: 7
    },
    geocode: {
      region: 'au'
    }
  };
}

function createMap(options = {}) {
  const map = new GMaps({...defaults.map, ...options});
  maps.push(map);
  return map;
}

function addMarkerToMap(map, options = {}) {
  return map.addMarker({...defaults.markers.small.black, ...options});
}

function drawOverlayOnMap(map, contentData = {}, options = {}) {
  return map.drawOverlay({
    ...defaults[map.isAlt ? 'overlay_alt' : 'overlay'],
    ...{ content: (map.isAlt ? altPopoverTemplate : popoverTemplate)(contentData) },
    ...options,
  });
}

function geocode(options = {}) {
  let callback;

  function processResult(result) {

    //The first result is the most accurate.

    let addressComponents,
      postCode,
      locality,
      streetAddress,
      formattedAddress = result.formatted_address;
    addressComponents = result.address_components;
    result.types.fastEach(function (type) {
      if (type === "postal_code") {
        //post code, state and country
        postCode = formattedAddress.replace(
          addressComponents[1].long_name,
          addressComponents[1].long_name + ','
        );
      } else if (type === "locality") {
        locality = addressComponents[0].long_name;
      } else if (type === "street_address") {
        streetAddress = formattedAddress.substr(0, formattedAddress.indexOf(','));
      }
    });

    return {
      postCode: postCode,
      locality: locality,
      streetAddress: streetAddress
    };
  }

  function processResults(results) {
    let address = {};
    mapOver(function(result){
      address = {...processResult(result), ...address}
    }, results);
    return address;
  }

  function geocodeCallback(results, status) {
    if (status !== 'OK') {
      callback(false);
    } else if (options.address) {
        callback(results);
    } else {
      callback(processResults(results));
    }
  }

  options = {...defaults.geocode, ...options}
  callback = options.callback;
  options.callback = geocodeCallback;

  return GMaps.geocode(options);
}

function createIndexMap(mapElementID) {
  let map,
    markers;
  const $mapElement = $('#' + mapElementID),
    $mapArea = $mapElement.closest('.mapArea'),
    $markerDataElements = $mapArea.find('*[data-map-marker]');

  function addPlaceMarker(data) {
    markers[data.url] = addMarkerToMap(map, {
      lat: data.latitude,
      lng: data.longitude,
      title: data.title + ' (' + data.count + ' location' + (data.count === 1 ? '' : 's') + ')',
      click: function () {
        window.location = data.url;
      }
    });
  }

  // Create the map UI control
  map = createMap({
    div: '#' + mapElementID,
    disableDefaultUI: true,
    zoomControl: true,
    scrollwheel: false
  });

  // Define the markers dictionary
  markers = {};

  // Add markers to the map UI for each place
  $markerDataElements.each(function () {
    const data = $(this).data('map-marker');
    if (data.latitude && data.longitude) {
      addPlaceMarker(data);
    } else {
      geocode({
        address: data.title + ', ' + rnn.site.network.isNZ ? 'New Zealand' : 'Australia',
        callback: function (results, status) {
          if (status === 'OK') {
            data.latitude = results[0].geometry.location.lat();
            data.longitude = results[0].geometry.location.lng();
            addPlaceMarker(data);
            map.fitZoom();
          }
        }
      });
    }
  });

  // Zoom the map to contain all of the markers
  map.fitZoom();
}

function createDetailMap(mapElementID) {
  // This will create the Google Map for Detail pages across websites
  // - note this was changed from the map api to limit costs
  let mapQuery;
  const $mapElement = $('#' + mapElementID),
    $detailMap = $mapElement.closest('.detailMap'),
    mapData = $mapElement.data('map-marker'),
    mapAddress = mapData.address,
    mapLatitude = mapData.latitude,
    mapLongitude = mapData.longitude;

  if (mapAddress !== '') {
    mapQuery = mapAddress;
  }

  else if (mapLatitude !== '' && mapLongitude !== '') {
    mapQuery = mapLatitude + ',' + mapLongitude;
  }

  else {
    $detailMap.hide();
  }

  $detailMap.html(
    $('<iframe />')
      .attr('allow', 'fullscreen')
      .css({
        'width': '100%',
        'height': '100%',
        'border': '0'
      })
      .attr(
        'src',
        encodeURI(
          'https://www.google.com/maps/embed/v1/place?zoom=15&key=' +
            rnn.site.googleMapKey +
            '&q=' +
            mapQuery
        )
      ).attr(
        'title',
        'Google Map of ' + mapQuery
      )
  );
}

function showMap($mapArea) {
  let showEvent;

  Cookies.set('searchResultsMap', 'show', { path: '/' });

  //Change state buttons
  $mapArea.find('.btn.showMap').addClass('active');
  $mapArea.find('.btn.hideMap').removeClass('active');

  $mapArea.removeClass('map-hidden');

  showEvent = $.Event('show');
  showEvent.target = $mapArea[0];

  $(rnn).trigger(showEvent);
}

function hideMap($mapArea) {
  let hideEvent;

  Cookies.set('searchResultsMap', 'hide', { path: '/' });

  if (!$mapArea.hasClass('mapAlwaysVisible')) {
    //Change state buttons
    $mapArea.find('.btn.hideMap').addClass('active');
    $mapArea.find('.btn.showMap').removeClass('active');

    $mapArea.addClass('map-hidden');

    hideEvent = $.Event('hide');
    hideEvent.target = $mapArea[0];

    $(rnn).trigger(hideEvent);
  }
}

function panMapToMarker($mapArea, marker) {
  const map = $mapArea.data('map');
  map.map.panTo(marker.getPosition());
}

function activateMarker($mapArea, markerId) {
  let popover = $mapArea.data('popover');
  const map = $mapArea.data('map'),
    markers = $mapArea.data('markers'),
    marker = markers[markerId];

  //Remove the old popover if it is still visible.
  popover && map.removeOverlay(popover);

  mapOver(function (siblingMarker, mapMarkerKey) {
    let $mapLink, contentData;

    $mapLink = $mapArea.find('[data-map-marker-key="' + mapMarkerKey + '"]');

    if (marker.active || mapMarkerKey !== markerId) {


      $mapLink.removeClass('active');

      siblingMarker.setOptions({
        zIndex: undefined,
        icon: markerStyleData[map.inactiveMarkerIconKey]
      });
      siblingMarker.active = false;

      siblingMarker.setOptions({
        zIndex: undefined,
        icon: markerStyleData[map.inactiveMarkerIconKey]
      });

      siblingMarker.active = false;
    } else {
      //draw popover

      if (map.isAlt && siblingMarker.data.renderedPopoverContent) {
        contentData =  {
          renderedPopoverContent: siblingMarker.data.renderedPopoverContent
        };
      } else {
        contentData = {
          title: siblingMarker.data.title,
          body: siblingMarker.data.address,
          url: siblingMarker.data.url
        };
      }

      popover = drawOverlayOnMap(map, contentData, {
        lat: siblingMarker.data.latitude,
        lng: siblingMarker.data.longitude
      });

      $mapArea.data('popover', popover);

      //pan map to marker
      panMapToMarker($mapArea, siblingMarker);
      if (map.isAlt) {
        map.map.panBy(140, 20);
      } else {
        map.map.panBy(0, -80);
      }

      //Set link to active
      $mapLink.addClass('active');

      let activateEvent = $.Event('activateMarker');
      activateEvent.target = $mapLink[0];
      $mapLink.trigger(activateEvent);

      //change marker style to active
      siblingMarker.setOptions({
        zIndex: google.maps.Marker.MAX_ZINDEX,
        icon: markerStyleData[map.activeMarkerIconKey]
      });

      marker.active = true;
      showMap($mapArea);
    }
  }, markers);
}

function addSearchResultMarker($mapArea, markerData, markers) {
  let marker;
  const map = $mapArea.data('map');

  if (!markers[markerData.url]) {

    marker = addMarkerToMap(map, {
      lat: markerData.latitude,
      lng: markerData.longitude,
      title: markerData.title,
      icon: markerStyleData[map.inactiveMarkerIconKey],
      click: function () {
        activateMarker($mapArea, markerData.url);
      }
    });
    marker.data = markerData;
    markers[markerData.url] = marker;
  }
}

function initialiseSearchResultsMap($mapArea){
  let map,
    markers;
  const mapId = $mapArea.find('.map').attr('id'),
      ignoreCookies = $mapArea.data('ignoreCookies');

  if(!ignoreCookies){
    //Do show/hide map
    (Cookies.get('searchResultsMap') === 'show' ? showMap($mapArea) : hideMap($mapArea));
  }

  // Create the map UI control
  map = createMap({
    div: '#' + mapId,
    disableDefaultUI: true,
    zoomControl: true,
    scrollwheel: false
  });

  map.isAlt = $mapArea.find('.altMap').length;
  map.activeMarkerIconKey = 'icon-small' + (map.isAlt ? '-alt' : '') + '-blue';
  map.inactiveMarkerIconKey = 'icon-small' + (map.isAlt ? '-alt' : '') + '-black';

  $mapArea.data('map', map);

  // Define the markers dictionary
  markers = {};
  $mapArea.data('markers', markers);

  // Add markers to the map UI for each search result
  $mapArea.find('*[data-map-marker]').each(function () {
    const markerData = $(this).data('map-marker');
    if (markerData.latitude && markerData.longitude) {
      addSearchResultMarker($mapArea, markerData, markers);
    }
  });

  // Zoom the map to contain all of the markers
  map.fitZoom();
  $mapArea.find('.map').width('100%').height('200px');

}

function createSearchResultsMap(mapElementID) {
  const $mapElement = $('#' + mapElementID),
    $mapArea = $mapElement.closest('.mapArea'),
    // Get the elements that will populate markers on the map
    $markerDataElements = $mapArea.find('*[data-map-marker]');

  // If no maker data elements
  if ($markerDataElements.length === 0) {

    // Hide everything map-related on the page
    $mapArea.find('.mapControls').hide();
    $mapElement.parent().hide();
    $('.not-on-map').hide();

  } else {

    initialiseSearchResultsMap($mapArea);
  }
}

export function createPopover(map, marker) {
  return drawOverlayOnMap(map, {
    title: 'Add a location',
    body: 'Drag the pin to desired location',
    url: 'javascript:void(0);'
  }, {
    lat: marker.getPosition().lat(),
    lng: marker.getPosition().lng()
  });
}

// Initialise Maps
function initMaps() {
  rnn = window.rnn
  initMapMarkerData();
  initDefaults();
  const indexMaps = $('.map.indexMap'),
    detailMaps = $('.map.detailMap'),
    searchResultsMaps = $('.map.searchResultsMap');

  detailMaps.each(function(){
    const map = $(this),
      modal = map.closest('.modal');

    if (modal.length) {
      modal.one('shown', function(){
        createDetailMap(map.attr('id'));
      });
    } else {
      createDetailMap(map.attr('id'));
    }
  });

  $('.mapArea')
    .on('click', '.showMap', function (event) {
      event.preventDefault();

      searchResultsMaps.each(function(){
        createSearchResultsMap($(this).attr('id'));
      });

      indexMaps.each(function(){
        createIndexMap($(this).attr('id'));
      });

      showMap($(this).closest('.mapArea'));
    })
    .on('click', '.hideMap',  function (event) {
      event.preventDefault();
      hideMap($(this).closest('.mapArea'));
    })
    .on('click', 'a.map-link', function (event) {
      event.preventDefault();
      const $mapArea = $(this).closest('.mapArea'),
        markerId = $(this).data('map-marker-key'),
        marker = $mapArea.data('markers')[markerId];
      panMapToMarker($mapArea, marker);
      activateMarker($mapArea, markerId);
    }).on('click', '.map-wrapper.collapsed', function(event){
      event.stopPropagation();
      $(this).removeClass('collapsed');
    });

  $('.getDirections').click(function(event){
    const $directionsLink = $(this);

    if (navigator.geolocation) {
      event.preventDefault();
      navigator.geolocation.getCurrentPosition(
        function (position) {
          document.location = $directionsLink.attr('href') + '&saddr=' + position.coords.latitude + "," + position.coords.longitude;
        },
        function () {
          document.location = $directionsLink.attr('href');
        }
      );
    }
  });

  $(rnn).on('show', '.mapArea', function(event){
    const $mapArea = $(event.target);

    $mapArea.find('.btn.showMap').addClass('active');
    $mapArea.find('.btn.hideMap').removeClass('active');
  });

  $(rnn).on('hide', '.mapArea', function(event){
    const $mapArea = $(event.target);

    $mapArea.find('.btn.hideMap').addClass('active');
    $mapArea.find('.btn.showMap').removeClass('active');
  });
}

export default async function Maps(site){
  const allMaps = [...document.querySelectorAll('.map.indexMap, .map.detailMap, .map.searchResultsMap')]
  if(allMaps.length === 0){
    return console.info('No maps on page, google maps will not be loaded.')
  }
  const loader = new Loader({
    apiKey: site.googleMapKey,
    version: "weekly",
  })

  loader.importLibrary('core').then(async () => {
    initMaps()
  });

}

constants = {
  AU_LAT: -25.274398,
  AU_LON: 133.775136,
  NZ_LAT: -40.900557,
  NZ_LON: 174.885971
};

const popoverTemplate = ({url = '', body = '', title = ''}) => `<div class="popover top" style="display: block;">
  <div class="arrow"></div>
  <div class="popover-inner">
    ${ title ? `<h3 class="popover-title"><a href="${url}">${title}</a></h3>` : '' }
    <div class="popover-content"><p>${body}</p></div></div>
</div>`
const altPopoverTemplate = ({renderedPopoverContent = ''}) => `<div class="alt-popover right">${ renderedPopoverContent }</div>`
