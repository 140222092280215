export default class RefineSearch {
  constructor(toggleElement, closeElement, drawerElement, classToToggle) {
    this.toggleElement = toggleElement;
    this.closeElement = closeElement;
    this.drawerElement = drawerElement;
    this.classToToggle = classToToggle;
    this.rootElement = document.body;

    // Mobile filter
    this.mobileOpenElement = document.querySelector('[data-interaction-refine-open-mobile]') || '';
    this.mobileCloseElement = document.querySelector('[data-interaction-refine-close-mobile]') || '';

    if (!this.mobileOpenElement) {
      console.info("No mobile refinements");
      return;
    }

    // If constants weren't loaded in time by DOM load (most likely for WL), use these fallbacks
    if (!this.toggleElement) this.toggleElement = document.querySelector('[data-interaction-refine-toggle]');
    if (!this.drawerElement) this.drawerElement = document.querySelector('[data-interaction-refine-drawer]');
    if (!this.closeElement) this.closeElement = document.querySelector('[data-interaction-refine-close]');

    if (!this.toggleElement) {
      console.info("No refinements");
      return;
    }


    this.overlayEl = document.querySelector('.filter-overlay') || '';

    this.addEventListeners();
  }

  addEventListeners() {
    // arrow functions guarantee the binding of the class (equivalent to .bind())
    this.toggleElement.addEventListener('click',
      evt => this.toggleRefine(evt));

    if (this.closeElement) {
      this.closeElement.addEventListener('click',
        evt => this.closeRefine(evt));
    }

    // Close filters by clicking outside
    if (this.overlayEl) {
      this.overlayEl.addEventListener('click',
        evt => this.closeRefine(evt));
    }

    // Listen for esc/tab events
    window.addEventListener('keydown',
      evt => this.handleKeyDown(evt));

    // Mobile
    if (this.mobileOpenElement) {
      this.mobileOpenElement.addEventListener('click',
        evt => this.openRefine(evt));
    }
    if (this.mobileCloseElement) {
      this.mobileCloseElement.addEventListener('click',
        evt => this.closeRefine(evt));
    }

    window.addEventListener('resize', evt => this.onWindowResize(evt));
  }

  toggleRefine(e) {
    e.stopPropagation();
    e.preventDefault();

    this.rootElement.classList.toggle(this.classToToggle);

    // For accessibility, set focus and allow tabbing between dialog elements only
    this.focusableEls = [...this.drawerElement.querySelectorAll('a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]')];
    this.firstFocusableEl = this.focusableEls[0];
    this.lastFocusableEl = this.focusableEls[ this.focusableEls.length - 1 ];

    this.focusedElBeforeOpen = document.activeElement;
    if(this.lastFocusableEl){ this.lastFocusableEl.focus(); }

    // This is needed to let Vue-menus break out of the box once it finish animating
    if (this.drawerElement.getAttribute("aria-hidden") === "true") {
      setTimeout(e => {
      if (this.drawerElement.getAttribute("aria-hidden") === "false") { this.drawerElement.style.overflow = "visible"; }
        }, 600);
    } else {
      this.drawerElement.style.overflow = "hidden";
    }

    // Toggle filters
    this.drawerElement.setAttribute("aria-hidden",
      this.drawerElement.getAttribute("aria-hidden") === "true" ? "false" : "true");
    this.overlayEl.setAttribute("aria-hidden",
      this.overlayEl.getAttribute("aria-hidden") === "true" ? "false" : "true");
  }

 handleKeyDown(e) {
    var Dialog = this;
    function handleBackwardTab() {
      if (document.activeElement === Dialog.firstFocusableEl) {
        e.preventDefault();
        Dialog.lastFocusableEl.focus();
      }
    }

    function handleForwardTab() {
      if (document.activeElement === Dialog.lastFocusableEl) {
        e.preventDefault();
        Dialog.firstFocusableEl.focus();
      }
    }

    switch (e.key) {
      case "Tab":
    		if (Dialog.focusableEls.length === 1) {
          e.preventDefault();
          break;
        }

        if (e.shiftKey) {
          handleBackwardTab();
        } else {
          handleForwardTab();
        }

        break;
      case "Escape":
    		this.closeRefine(e);
        break;
      default:
  		break;
    }
  }

  closeRefine(e) {
    e.stopPropagation();
    this.drawerElement.style.overflow = "hidden";
    this.rootElement.classList.remove(this.classToToggle);
    this.drawerElement.setAttribute("aria-hidden", "true");
    this.overlayEl.setAttribute("aria-hidden", "true");
    if (this.focusedElBeforeOpen) {
      this.focusedElBeforeOpen.focus();
    }
    // Mobile close
    if (this.mobileRefinement) {
      this.mobileRefinement = document.getElementById("refinement-filters") || ''; // Needed as vue hasn't inserted the element into DOM on load
      this.mobileRefinement.classList.remove('refinement-form__show');
      this.mobileRefinement.classList.add('refinement-form__hide');
    }
  }

  openRefine(e) {
    e.stopPropagation();
    e.preventDefault();

    this.mobileRefinement = document.getElementById("refinement-filters") || ''; // Needed as vue hasn't inserted the element into DOM on load
    if(this.mobileRefinement) {
      this.rootElement.classList.add(this.classToToggle);
      this.mobileRefinement.classList.add('refinement-form__show');
      this.mobileRefinement.classList.remove('refinement-form__hide');
    }
  }

  onWindowResize(e) {
    // catering for a resize when the menu is showing
    if (window.innerWidth > 768) {
      this.closeRefine(e);
    }
  }
}
