import axios from 'axios';

import RefineSearch from '~/common/js/modules/refine-filters.js';
import attachOnSelectChangeUrlHandler from './modules/search-result-sort.js';

import Ads from '~/common/js/ads.js';
import utils from './modules/utils.js';
import selectTargetTabMenu from './modules/tabs-menu.js';
import HeroCarousel from './modules/HeroCarousel.js';
// import initCarousel from './modules/carousel-lazy-susan.js';
import checkLoginUser from './modules/user.js';
import initCrossDomainLinking from './modules/cross-domain-linking.js';
import GliderCarousel from './modules/glider-carousel.js';
import Header from './modules/header.js';
import Cards from '~/common/js/Cards'
import '~/common/js/modules/modal'
import RefinementFilters from './RefinementFilters.jsx'
import { bindTracking } from '~/common/js/rnn/Omniture';
import Forms from './Forms'
import attachTogglingOfAccordion from './modules/accordion-expanding.js';

import {
  SHOW_REFINEMENT_CLASS,
  refineEl,
  drawerEl,
  refineCloseEl,
  searchInputEl,
  formExpandEl,
  mobileSearchTitleEl,
  mobileSearchInputEl,
  closeMobileFormEl,
  searchFormEl,
  searchIconEl,
  documentBodyEl
} from './modules/constants.js';

// Global Modules
import u from 'umbrellajs'; // https://umbrellajs.com - jQuery 'lite' syntax
import Cookies from 'js-cookie';
window.Cookies = Cookies

// @NOTE Images to import into the buildchain. Anything not referenced by webpack
// will not be included in the final build
import "../../common/images"
import "../images/adults_heels.svg"
import "../images/nick-thinking.svg"
import "../images/buysearchsell_white_logo.svg"
import "../images/box-of-toys.svg"
import "../images/single-promo-section-background.jpg"
import "../images/JoyOveralls.svg"
import "../images/adults_heels.svg"
import "../images/transition-newsconcierge.gif"
import "../images/logo-news-concierge.svg"
import "../images/favicon.svg"
import "../images/favicon-32x32.png"
import "../images/apple-touch-icon.png"
import "../images/Trades_Services_Clothes.svg"
// This image is used in rnn-standalone transition page only currently, we may need to remove it when migrating to NewsFinda
import "../images/buysearchsell_black_logo.svg"

// Global Config
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// Default init function
export default function () {
  Ads()
  HeroCarousel()
  const topPicksEl = document.getElementById("glider-top");
  if(topPicksEl) {
    // Create Mobile Top Picks Carousel
    const gliderArrowsEl = document.getElementsByClassName('glider__controller'),
          gliderDotsEl = document.querySelector('#dots'),
          gliderArrowPrev = document.querySelector('#glider__prev-control'),
          gliderArrowNext = document.querySelector('#glider__next-control'),
          topPickCardEl = document.getElementsByClassName('glider-slide'),
          showOnDesktop = false;

    new GliderCarousel(topPicksEl, gliderArrowsEl, gliderDotsEl, gliderArrowPrev, gliderArrowNext, topPickCardEl, showOnDesktop);
  }

  // Init Header and footer
  new Header(searchInputEl, formExpandEl, mobileSearchTitleEl, mobileSearchInputEl, closeMobileFormEl, searchFormEl, searchIconEl, documentBodyEl);
  RefinementFilters()
  attachTogglingOfAccordion('[data-interaction-accordion]')

  // Init Search Refinement Filters
  new RefineSearch(refineEl, refineCloseEl, drawerEl, SHOW_REFINEMENT_CLASS);

  attachOnSelectChangeUrlHandler('[data-interaction-sort]');
  attachOnSelectChangeUrlHandler('[data-interaction-per-page]');

  selectTargetTabMenu();
  checkLoginUser();
  utils();
  initCrossDomainLinking();
  // initCarousel();

  Cards()

  // Remove preload class to start css animations at the same time
  document.querySelectorAll('.preload').forEach(x => x.classList.remove('preload'));
  // Open external links in a new tab and add 'nofollow'
  const url = window.location.pathname
  const last = url.split("/")
  const adID = (last[last.length-2])
  u('#sanitised-text a')
  .filter(link => link.hostname !== location.hostname)
  .each(link =>
    u(link)
      .attr('target', '_blank')
      .attr('rel', 'nofollow')
      .attr('data-omniture-tracking', JSON.stringify({clickStoreEvar: true, eVar: 'eVar23', value: adID + ' - ' + link.href}))
  )
  Forms()
  bindTracking()
}
