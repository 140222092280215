
export default function checkLoginUser() {
  window.rnn.modules.user.get(showProfileMenu)

  function showProfileMenu(user) {
    if (user.authenticated) {
      document.querySelectorAll('.authenticated').forEach((element) => {
        element.classList.remove('authenticated');
      });

      document.querySelectorAll('.unauthenticated').forEach((element) => {
        element.remove()
      });

      document.querySelectorAll('.authenticated-user-first-name').forEach((element) => {
        element.innerText = user.first_name;
      });

      document.querySelectorAll('.authenticated-user-avatar').forEach((element) => {
        if (user.encoded_avatar_url) {
          element.innerHTML = '<img src="' + decodeURIComponent(user.encoded_avatar_url) + '">'
        } else {
          element.innerHTML = '<span class="bss-icon-user"></span>';
        }
      });
    }
  }
}
